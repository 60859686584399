<template>
	<div class="wrapper">
		<div class="table">
			<vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
			</vxe-grid>
			<vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize" :page-sizes="tablePage.pageSizes"
			 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapActions,
    mapMutations
	} from "vuex";
	import api from '@/store/API/api'
	export default {
		data() {
			return {
				loading: false,
				seachStatus: false,
				searchData: {},
				// 分页的参数配置
				tablePage: {
					totalResult: 0,
					currentPage: 1,
					pageSize: 10,
					align: "left",
					pageSizes: [10, 20, 50, 100, 500, 1000],
					perfect: true
				},
				tableColumn: [{
						type: 'seq',
						title: '序号',
						width: 50,
						align: "center"
					},
					{
						field: 'oldPersonName',
						title: '老人姓名',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'idNum',
						title: '老人身份证号',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'serviceTimes',
						title: '服务次数',
						showOverflow: true,
						align: "center",
						minWidth: "60"
					},
					{
						field: 'usedPoints',
						title: '已使用积分',
						showOverflow: true,
						align: "center",
						minWidth: "60"
					},
					{
						field: 'remainPoints',
						title: '剩余积分',
						showOverflow: true,
						align: "center",
						minWidth: "60"
					},
					{
						field: 'tel',
						title: '老人联系方式',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					},
					{
						field: 'lastServiceBeginTime',
						title: '最后服务开始时间',
						showOverflow: true,
						align: "center",
						minWidth: "120"
					}
				],
				tableData: [],
			}
		},
		computed: {
			...mapState([
				"layouts"
			]),
			...mapGetters(['seqId'])
		},
		watch: {
			seqId() {
				// this.getLists()
			}
		},
		created() {
			this.initView()
		},
		methods: {
			...mapActions([
				"getServiceObjectList",
				"deletePractitioner",
				"exportPractitioner"
			]),
			initView() {
				// this.getTeamList();
				// this.getCategoryList();
				this.getLists();
			},
			// 是否显示查询功能
			showSearch(bool) {
				this.seachStatus = bool;
			},
			goPage(type, itemData) {
				this.$router.push({
					name: 'AddPractitioners',
					params: {
						type: type,
						id: type == 'add' ? 'add' : itemData.id
					},
					query: {
						item: type == 'add' ? 'add' : JSON.stringify(itemData)
					}
				})
			},
			getLists() {
				this.loading = true;
				this.getServiceObjectList({
					current: this.tablePage.currentPage,
					size: this.tablePage.pageSize,
					param: {
						workSiteId: this.$route.params.id
					}
				}).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.records;
						this.tablePage.totalResult = +res.data.total;
					}
					this.loading = false;
				})
			},
			// 分页功能
			pageChange(item) {
				if (item.type == "size") {
					this.tablePage.currentPage = 1;
				} else {
					this.tablePage.currentPage = item.currentPage;
				}
				this.getLists();
			},
			remove(id) {
				this.$XModal.confirm("您确定要删除该数据?").then(type => {
					if (type === "confirm") {
						console.log('执行删除操作');
						this.delete(id)
					}
				})
			},
			delete(id) {
				this.deletePractitioner({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.$XModal.message({
							message: '删除成功',
							status: "success"
						});
						this.getLists()
					}
					this.loading = false;
				})
			},
			// 导出功能
			exportData() {
        let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
        url += api.exportPractitioner
				let myObj = {
					method: 'post',
					url,
					fileName: '从业人员',
					params: {
					    current: this.tablePage.currentPage,
					    size: this.tablePage.pageSize,
						param: {
						    perName: this.searchData.perName,
						    contTel: this.searchData.contTel,
						    workSiteName: this.searchData.workSiteName
						}
					}
                }
                this.$tools.exportMethod(myObj)
			},
		}
	}
</script>

<style lang="scss" scoped>
    .table{
		overflow-y:auto;
	}
	.wrapper{
	  .datetime{
	    display: flex;
	    align-items: center;
	    span{
	      margin: 0 3px;
	    }
	  }
	}
	.sign i {
		margin-right: 3px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}
</style>
